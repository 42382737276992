import React from "react";

import LPLogo from "assets/img/brand/lp/justicia-logo-lp.png"
import LPLogoLight from "assets/img/brand/lp/justicia-logo-lp-light.png";
import LPFav from "assets/img/brand/lp/lp_iso.png";
import DefaultLP from "assets/img/brand/lp/defaultLaPampa.png";

import InterBannerWA from "assets/img/brand/subastas_electronicas/inter_banners/Inter_bannerWA.webp";


export const site = 'lp';

export const sources = {
    lp: {
        url: document.location.origin,
        logo: LPLogo,
        logolight: LPLogoLight,
        ws_path: `${document.location.origin}/wss`.replace('http', 'ws').replace('https', 'wss'),
        name: 'Subastas Judiciales La Pampa',
        navbar_class: 'navbar-lp',
        headroom_class: 'headroom-lp',
        favicon: LPFav,
        theme_color: '#5b3434',
        theme_name: 'lapampa',
        defaultImg: DefaultLP,
        interBanner: [{img: InterBannerWA, link: 'https://chat.whatsapp.com/FW2ngM0l3nNBHdL69N2eAo'}],

        title: 'Subastas Judiciales',
        recaptcha_key: '6Leb2oEeAAAAALdNh_TPAPActnAm-Kvw7Vb9kRXx',
        gtm_ID: 'GTM-TKF36DJ',
        contact_data: {
            title: <><h5>Secretaría de subastas</h5>
                    <h5>Poder Judicial de la Provincia de La Pampa</h5>
                </>,
            lines: [
                    <p><b>Avda Uruguay n° 1097 - Tercer piso</b></p>,
                    <p><b>Centro Judicial Santa Rosa</b></p>,
                    <p><b>Teléfono:</b></p>,
                    <p>&emsp; 02954 - 452099 (7AM a 12:30PM)</p>,
                    <p><b>email:</b></p>,
                    <p>&emsp; servijuris@juslapampa.gob.ar</p>,
                    <br />,
                    <h5>Secretaría de sistemas y organización:</h5>,
                    <p><b>Teléfono:</b></p>,
                    <p>&emsp; 02954 - 451866 (Mesa de ayudas)</p>,
            ]
        },
        support_email: 'soportelp@esubasta.ar'
    },
};